import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import Select from 'react-select';
import { MdModeEdit } from 'react-icons/md';
import { BsFillTrashFill } from 'react-icons/bs';
import { GrFormView } from 'react-icons/gr';

import styles from './MyWorkTable.module.scss';
import { selectTheme } from '../../../utils/select-field-utils';
import useWorkflowApi from '../../../hooks/useWorkflowApi';
import useAuthApi from '../../../hooks/useAuthApi';

const BuildAction = (handleActionChange, setViewLinkUrl, updatingTasks, allWork, e) => {
  const { row } = e;
  const { original } = row;
  const { id, wipName, taskId, user } = original;
  const { getTasks, tasksData } = useWorkflowApi();
  const { userDataFromStore } = useAuthApi();
  const username = userDataFromStore?.username;
  const initActionOptionsForAllWork = [
    {
      value: { id, user, taskId },
      label: (
        <>
          <MdModeEdit /> Edit
        </>
      ),
    },
    { value: id, label: 'Create Proof' },
    {
      value: { id, wipName },
      label: (
        <>
          <BsFillTrashFill /> Delete
        </>
      ),
    },
    {
      value: id,
      label: (
        <>
          <GrFormView size={20} /> View
        </>
      ),
    },
  ];

  const [actionOptions, setActionOptions] = useState([
    {
      value: { id, user, taskId },
      label: (
        <>
          <MdModeEdit /> Edit
        </>
      ),
    },
    { value: id, label: 'Create Proof' },
    {
      value: { id, wipName },
      label: (
        <>
          <BsFillTrashFill /> Delete
        </>
      ),
    },
  ]);

  const loading = [{ id: 'loading', label: <b>Loading...</b> }];
  const [actionOptionsForAllWork, setActionOptionsForAllWork] = useState(
    user === username || user === 'unclaimed' ? initActionOptionsForAllWork : initActionOptionsForAllWork.slice(1)
  );

  useEffect(() => {
    if (tasksData && actionOptions.length === 3) {
      // Make a copy of the options
      const newActionOptions = [...actionOptions];
      const newActionOptionsForAllWork = [...actionOptionsForAllWork];

      // Mutate the copy as needed.
      if (tasksData.task.state !== 'claimed') {
        newActionOptions.splice(1, 0, { label: 'Claim', value: taskId });
        newActionOptionsForAllWork.splice(1, 0, { label: 'Claim', value: taskId });
      }

      if (tasksData.task.state === 'claimed' && tasksData.task.candidates.length !== 0) {
        newActionOptions.splice(1, 0, { label: 'Unclaim', value: taskId });
        newActionOptionsForAllWork.splice(1, 0, { label: 'Unclaim', value: taskId });
      }

      tasksData.task.actions.forEach((action) => {
        newActionOptions.splice(1, 0, {
          label: action.label,
          value: { name: action.name, id: taskId, processId: id, wipName },
        });

        newActionOptionsForAllWork.splice(1, 0, {
          label: action.label,
          value: { name: action.name, id: taskId, processId: id, wipName },
        });
      });

      setActionOptions(newActionOptions);
      setActionOptionsForAllWork(newActionOptionsForAllWork);
    }
  }, [actionOptions, actionOptionsForAllWork, taskId, tasksData, id, user, wipName]);

  if (!handleActionChange) {
    return (
      <Button
        className="p-0"
        color="link"
        onClick={() => {
          setViewLinkUrl(id);
        }}
      >
        <GrFormView size={32} /> View
      </Button>
    );
  }

  return (
    <Select
      name="action"
      value="Select"
      onChange={handleActionChange}
      // eslint-disable-next-line no-nested-ternary
      options={actionOptions.length <= 3 ? loading : allWork ? actionOptionsForAllWork : actionOptions}
      className={styles.input}
      theme={selectTheme}
      menuPlacement="bottom"
      isDisabled={updatingTasks}
      onMenuOpen={() => {
        getTasks(taskId);
      }}
    />
  );
};

export default BuildAction;
