import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, Button } from 'reactstrap';
import { useParams, NavLink as RRDNavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import FeatureDetailTabs from './FeatureDetailTabs/FeatureDetailTabs';
import BackLink from '../../components/BackLink/BackLink';
import { Routes } from '../../App.constants';
import useFeatureApi from '../../hooks/useFeatureApi';
import useP2PInstitutionalApi from '../../hooks/useP2PInstitutionalApi';
import useP2PConsumerApi from '../../hooks/useP2PConsumerApi';

const FeatureDetailPage = () => {
  const { featureId } = useParams();
  const { getFeature, featureMetadata, errorGettingFeatureData, loadingFeatureData } = useFeatureApi();
  const { p2pConsumerFeature, p2pFeatureData, errorP2PingFeatureGroup } = useP2PConsumerApi();
  const {
    p2pInstitutionalFeature,
    p2pFeatureData: p2pInstFeatureData,
    errorP2PingFeatureGroup: errorP2PingInstFeatureGroup,
  } = useP2PInstitutionalApi();
  const errorAlreadySubmitted = errorP2PingInstFeatureGroup?.status === 409 || errorP2PingFeatureGroup?.status === 409;
  const error = errorP2PingInstFeatureGroup || errorP2PingFeatureGroup;
  const submitted = p2pFeatureData || p2pInstFeatureData;
  const [p2pFeature, setp2pFeature] = useState(false);

  useEffect(() => {
    if (submitted) {
      toast.success('P2P submitted', {
        toastId: 'p2p-submitted',
      });
    }
  }, [submitted]);

  useEffect(() => {
    if (error) {
      if (errorAlreadySubmitted) {
        toast.success('P2P already submitted', {
          toastId: 'p2p-already-submitted',
        });
      } else {
        toast.error('Cannot P2P', {
          toastId: 'cannot-p2p',
        });
      }
    }
  }, [error, errorAlreadySubmitted]);

  // Get feature on mount
  useEffect(() => {
    getFeature(featureId);
  }, [featureId, getFeature]);

  useEffect(() => {
    if (p2pFeature) {
      if (featureMetadata.product === 'School') {
        p2pInstitutionalFeature({ featureId: featureMetadata?.featureId });
      } else {
        p2pConsumerFeature({ featureId: featureMetadata?.featureId });
      }

      setp2pFeature(!p2pFeature);
    }
  }, [featureMetadata?.featureId, featureMetadata?.product, p2pConsumerFeature, p2pFeature, p2pInstitutionalFeature]);

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: Feature Detail {featureId}</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <BackLink to={Routes.FEATURE_SEARCH.toLink()} linkText="Feature Search" />
            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Feature Detail</h1>
              <Nav className="pb-1">
                <NavItem>
                  <NavLink
                    to={{
                      pathname: Routes.WIP_CREATE.toLink(),
                      state: {
                        backLink: { to: Routes.FEATURE_SEARCH.toLink(), text: 'Feature Search' },
                        name: featureMetadata?.name,
                        featureId: featureMetadata?.featureId,
                        featureProductId: featureMetadata?.featureProductId,
                      },
                    }}
                    tag={RRDNavLink}
                    className="pt-0 pb-0"
                    disabled={!(!featureMetadata?.inwork && !featureMetadata?.deleted && !featureMetadata?.inactive)}
                  >
                    <Button
                      type="submit"
                      color="primary"
                      disabled={!(!featureMetadata?.inwork && !featureMetadata?.deleted && !featureMetadata?.inactive)}
                    >
                      Create WIP
                    </Button>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <Button type="submit" color="primary" onClick={() => setp2pFeature(!p2pFeature)}>
                    P2P Feature
                  </Button>
                </NavItem>
              </Nav>
              <span> </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <FeatureDetailTabs
              featureId={featureId}
              featureMetadata={featureMetadata}
              errorGettingFeatureData={errorGettingFeatureData}
              loadingFeatureData={loadingFeatureData}
            />
          </Col>
        </Row>
      </Container>
    </DefaultTemplate>
  );
};

export default FeatureDetailPage;
