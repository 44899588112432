import React, { useEffect, useState } from 'react';
import { Container, Row, Col, UncontrolledTooltip, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Helmet } from 'react-helmet';

import { EDITORIAL_API } from '../../App.constants';
import SpinnerComponent from '../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../components/SpinnerComponent/SpinnerComponent.constants';
import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import FullTextSearchForm from './FullTextSearchForm/FullTextSearchForm';
import FullTextSearchTable from './FullTextSearchTable/FullTextSearchTable';
import useFullTextApi from '../../hooks/useFullTextApi';
import { checkForEmptyData } from '../../utils/form-utils';
import { setFullTextSearchFormData, setFullTextSearchTableConfigPageIndex } from '../../store/action-creators';

const FullTextSearchPage = () => {
  const dispatch = useDispatch();
  const { getFullText, fullTextData, errorGettingFullText, loadingFullText, abortGettingFullText } = useFullTextApi();
  const [fullTextDataResults, setFullTextDataResults] = useState(null);
  const fullTextSearchFormData = useSelector((state) => state.fullTextSearchFormData);
  const [fullTextSearchRequestData, setFullTextSearchRequestData] = useState({
    text: fullTextSearchFormData.text,
    articleTypeId: fullTextSearchFormData.articleTypes?.value,
    corpusId: fullTextSearchFormData.corpus?.value,
    tags: fullTextSearchFormData.tags,
  });
  const pageIndexFromRedux = useSelector((state) => state.fullTextSearchTablePageIndex);
  const pageSizeFromRedux = useSelector((state) => state.fullTextSearchTablePageSize);
  const [totalCount, setTotalCount] = useState();

  // Every time fullTextSearchRequestData state is set make sure fullTextSearchRequestData
  // exists and is not empty and if so set appropriate state and get articles.
  // This will happen on mount (if there is data for the form in Redux) or on form submit.
  useEffect(() => {
    if (fullTextSearchRequestData && !checkForEmptyData(fullTextSearchRequestData)) {
      getFullText(fullTextSearchRequestData, pageIndexFromRedux + 1, pageSizeFromRedux);
    }
  }, [fullTextSearchRequestData, getFullText, pageIndexFromRedux, pageSizeFromRedux]);

  useEffect(() => {
    if (fullTextData) {
      setFullTextDataResults(fullTextData?.articles);
      setTotalCount(fullTextData?.page.totalCount);
    }
  }, [fullTextData]);

  const onSearch = (formData) => {
    setFullTextDataResults(null);
    setFullTextSearchRequestData({
      text: formData.text,
      articleTypeId: formData.articleTypes?.value,
      corpusId: formData.corpus?.value,
      tags: formData.tags,
    });

    dispatch(setFullTextSearchFormData(formData));
    dispatch(setFullTextSearchTableConfigPageIndex(0));
  };

  const onReset = () => {
    setFullTextDataResults(null);
    setFullTextSearchRequestData(null);
    dispatch(setFullTextSearchTableConfigPageIndex(0));
  };

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: Full Text Search</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">Full Text Search</h1>
              <Button color="link">
                <a
                  href={`${EDITORIAL_API}/bsc/docs/full-text-search-doc.html`}
                  className="p-0"
                  target="_blank"
                  rel="noreferrer noopener"
                  id="fullTextSearch"
                >
                  <AiOutlineQuestionCircle data-testid="help-icon" />
                </a>
                <UncontrolledTooltip placement="right" target="fullTextSearch">
                  Help for section
                </UncontrolledTooltip>
              </Button>
            </div>
          </Col>
        </Row>
        <FullTextSearchForm
          loading={loadingFullText}
          abort={abortGettingFullText}
          onSearch={onSearch}
          onReset={onReset}
          setFullTextSearchFormData={setFullTextSearchFormData}
          fullTextSearchFormData={fullTextSearchFormData}
        />
        <div className="d-flex justify-content-between align-items-center mb-3 border-bottom border-primary border-1">
          <h1 className="text-primary fw-bold">Results</h1>
        </div>
        {!errorGettingFullText && !loadingFullText && !fullTextDataResults && (
          <p>Use the form to search for articles...</p>
        )}
        {fullTextDataResults && !loadingFullText && !errorGettingFullText && (
          <FullTextSearchTable fullTextDataResults={fullTextDataResults} totalCount={totalCount} />
        )}
        {!loadingFullText &&
          errorGettingFullText &&
          (errorGettingFullText?.status === 404 || errorGettingFullText?.status === 400
            ? 'No articles were found.'
            : 'There was an issue loading the articles')}
        {loadingFullText && <SpinnerComponent size={spinnerSize1} setFullVh={false} />}
      </Container>
    </DefaultTemplate>
  );
};

export default FullTextSearchPage;
