import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button, Spinner } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import DefaultTemplate from '../../templates/DefaultTemplate/DefaultTemplate';
import useWorkflowApi from '../../hooks/useWorkflowApi';
import { spinnerSize1 } from '../../components/SpinnerComponent/SpinnerComponent.constants';
import SpinnerComponent from '../../components/SpinnerComponent/SpinnerComponent';
import MyWorkTable from './MyWorkTable/MyWorkTable';
import buildMyWorkTableRowsData from './MyWorkPage.utils';
import { Routes } from '../../App.constants';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import HelpIcon from '../../components/HelpIcon/HelpIcon';

const MyWorkPage = () => {
  const {
    getMyWork,
    myWorkData,
    errorGettingMyWork,
    loadingMyWork,
    errorDeletingWorkflow,
    deletingWorkflow,
    deleteWorkflow,
    deleteData,
    getViewLink,
    viewLinkData,
    updateTasks,
    updateData,
    errorUpdatingTasks,
    updatingTasks,
    getViewLinkValid,
    viewLinkValidData,
    errorGettingViewLinkValid,
    loadingViewLinkValid,
  } = useWorkflowApi();
  const debugMode = window.location.href.split('/').slice(-1).toString() === 'debug';
  const [tableRowsData, setTableRowsData] = useState([]);
  const [errorGettingMyWorkMessage, setErrorGettingMyWorkMessage] = useState('');
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showConfirmPromoteModal, setShowConfirmPromoteModal] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [post, setPost] = useState(false);
  const history = useHistory();
  const [claiming, setClaiming] = useState(false);

  useEffect(() => {
    getMyWork();
  }, [getMyWork]);

  useEffect(() => {
    if (myWorkData) {
      setTableRowsData(buildMyWorkTableRowsData(myWorkData.tasks));
    }
  }, [myWorkData]);

  // If there is an error during the fetch (400 or 404) remove the current processes
  // data from state and set the error messaging in state.
  useEffect(() => {
    if (errorGettingMyWork) {
      const errorStatus = errorGettingMyWork?.status;

      if (errorStatus === 404) {
        setErrorGettingMyWorkMessage('No processes were found.');
      } else if (errorStatus === 400) {
        setErrorGettingMyWorkMessage('Bad request');
      } else {
        setErrorGettingMyWorkMessage('There was an issue loading the processes.');
      }
    }
  }, [errorGettingMyWork]);

  // If there is an error during the delete (400 or 404) set the error messaging in state.
  useEffect(() => {
    if (errorDeletingWorkflow) {
      const errorStatus = errorDeletingWorkflow?.status;

      if (errorStatus === 404) {
        setErrorGettingMyWorkMessage('No WIP to delete was found.');
      } else if (errorStatus === 400) {
        setErrorGettingMyWorkMessage('Cannot delete WIP');
      } else {
        setErrorGettingMyWorkMessage('There was an issue deleting the WIP.');
      }
    }
  }, [errorDeletingWorkflow]);

  useEffect(() => {
    if (deleteData) {
      toast.success('WIP Deleted');
      setTimeout(() => window.location.reload(), 1000);
    }
  }, [deleteData]);

  const onConfirmDeleteButtonClick = () => {
    deleteWorkflow(showConfirmDeleteModal.id);
    setShowConfirmDeleteModal(false);
    history.push(Routes.MY_WORK.toLink());
  };

  useEffect(() => {
    if (viewLinkData) {
      if (viewLinkData.node.properties?.['cm:lockType'] === 'WRITE_LOCK') {
        toast.error('Article is already being edited');
      } else window.open(viewLinkData.oxygenEditUrl);

      if (claiming) {
        setClaiming(false);
        setTimeout(() => history.go(Routes.MY_WORK.toLink()), 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, viewLinkData]);

  useEffect(() => {
    if (viewLinkValidData) {
      if (viewLinkValidData.content.validation.isValid) {
        updateTasks({ action: { label: post.label, name: post.value.name } }, post.value.id);
      } else {
        toast.error('WIP not posted, document not valid');
        setTimeout(() => history.go(Routes.MY_WORK.toLink()), 1000);
      }
    }
  }, [viewLinkValidData, history, post, updateTasks]);

  useEffect(() => {
    if (updated) {
      if (post) {
        toast.success('WIP Posted');
      } else toast.success('WIP Updated');

      setUpdated(false);

      if (claiming) {
        getViewLink(claiming);
      } else {
        setTimeout(() => history.go(Routes.MY_WORK.toLink()), 1000);
      }
    }
  }, [claiming, getViewLink, history, post, updated]);

  useEffect(() => {
    if (updateData) {
      setUpdated(true);
    }
  }, [updateData]);

  // If there is an error during the update (400 or 404) set the error messaging in state.
  useEffect(() => {
    if (errorUpdatingTasks || errorGettingViewLinkValid) {
      setUpdated(false);

      const errorStatus = errorUpdatingTasks?.status;

      if (errorStatus === 403) {
        toast.error('WIP is claimed by another user');
        setTimeout(() => history.go(Routes.MY_WORK.toLink()), 1000);
      } else toast.error('Cannot update');
    }
  }, [errorUpdatingTasks, errorGettingViewLinkValid, history]);

  const handleActionChange = (selected) => {
    if (selected.label.props?.children[1] === ' Delete') {
      setShowConfirmDeleteModal(selected.value);
    } else if (selected.label.props?.children[1] === ' Edit') {
      if (selected.value?.user === 'unclaimed') {
        setClaiming(selected.value?.id);
        updateTasks({ state: 'claimed' }, selected.value.taskId);
      } else getViewLink(selected.value?.id);
    } else if (selected.label.includes('Promote') || selected.label.includes('Back')) {
      if (selected.label === 'Promote to Post' || selected.label === 'Promote to Index Title') {
        setShowConfirmPromoteModal(selected);
      } else {
        updateTasks({ action: { label: selected.label, name: selected.value.name } }, selected.value.id);
      }
    } else if (selected.label === 'Unclaim') {
      updateTasks({ state: 'unclaimed' }, selected.value);
    } else if (selected.label === 'Claim') {
      updateTasks({ state: 'claimed' }, selected.value);
    } else if (selected.label === 'Create Proof') {
      history.push(Routes.PROOF.toLink({ processId: selected.value }));
    }
  };

  const onConfirmPromoteButtonClick = () => {
    if (showConfirmPromoteModal.label === 'Promote to Post') {
      setPost(showConfirmPromoteModal);
      getViewLinkValid(showConfirmPromoteModal.value?.processId);
    } else {
      updateTasks(
        { action: { label: showConfirmPromoteModal.label, name: showConfirmPromoteModal.value.name } },
        showConfirmPromoteModal.value.id
      );
    }

    setShowConfirmPromoteModal(false);
  };

  return (
    <DefaultTemplate>
      <Helmet>
        <title>AM: My Work</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center mb-3 border-bottom border-primary border-1">
              <h1 className="text-primary fw-bold">My Work in Progress</h1>
              {(updatingTasks || deletingWorkflow || loadingViewLinkValid) && (
                <Button color="info" disabled className="me-2">
                  <Spinner className="me-2" as="span" animation="grow" size="sm" role="status" aria-hidden="true" />{' '}
                  Processing...
                </Button>
              )}

              <HelpIcon link="my-work" id="myWork" />
            </div>
          </Col>
        </Row>
        {myWorkData && !loadingMyWork && (
          <fieldset disabled={updatingTasks}>
            <MyWorkTable
              data={tableRowsData}
              handleActionChange={handleActionChange}
              updatingTasks={updatingTasks}
              debugMode={debugMode}
            />
          </fieldset>
        )}
        {errorGettingMyWorkMessage && errorGettingMyWorkMessage}
        {loadingMyWork && !myWorkData && (
          <div className="mt-3">
            <SpinnerComponent size={spinnerSize1} setFullVh={false} />
          </div>
        )}
        {showConfirmDeleteModal && (
          <ConfirmModal
            toggle={() => setShowConfirmDeleteModal(false)}
            header={`Confirm Delete '${showConfirmDeleteModal.wipName}'`}
            confirmButton="Delete"
            message="This WIP will be deleted from the system. All promotion history of the WIP will be deleted. The XML file will no longer be available and all edits will be lost. The associated article will be unlocked for editing. This action can not be undone."
            onConfirmButtonClick={onConfirmDeleteButtonClick}
          />
        )}
        {showConfirmPromoteModal && (
          <ConfirmModal
            toggle={() => setShowConfirmPromoteModal(false)}
            header={`Confirm '${showConfirmPromoteModal.label}'`}
            confirmButton="Promote"
            message={
              showConfirmPromoteModal.label === 'Promote to Index Title'
                ? `The WIP '${showConfirmPromoteModal.value?.wipName}' will be sent to the Index Title step`
                : ` The WIP '${showConfirmPromoteModal.value?.wipName}' will be posted and published online`
            }
            onConfirmButtonClick={onConfirmPromoteButtonClick}
          />
        )}
      </Container>
    </DefaultTemplate>
  );
};

export default MyWorkPage;
