import React from 'react';
import { Link } from 'react-router-dom';

import { Routes } from '../../../App.constants';
import TextColumnFilter from '../../../components/TextColumnFilter/TextColumnFilter';

const featureSearchTableColumns = [
  {
    Header: 'Feature ID',
    accessor: 'featureId',
    Filter: TextColumnFilter,
    Cell: (e) => {
      const { value } = e;

      return <Link to={Routes.FEATURE_DETAIL.toLink({ featureId: value })}>{value}</Link>;
    },
  },
  {
    Header: 'Name',
    accessor: 'name',
    Filter: TextColumnFilter,
    Cell: (e) => {
      const { row, value } = e;
      const { original } = row;
      const { featureId } = original;

      return <Link to={Routes.FEATURE_VIEW.toLink({ featureId })}>{value}</Link>;
    },
  },
  {
    Header: 'Feature Type',
    accessor: 'featureTypeName',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Language',
    accessor: 'languageName',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Tier',
    accessor: 'tierName',
    Filter: TextColumnFilter,
  },
  {
    Header: 'In Work',
    accessor: 'inwork',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Deleted',
    accessor: 'deleted',
    Filter: TextColumnFilter,
  },
  {
    Header: 'Action',
    disableFilters: true,
    id: 'wipActionLink',
    Cell: (e) => {
      const { row } = e;
      const { original } = row;
      const { name, featureId, productId, inwork, deleted, inactive } = original;

      if (inwork === 'N' && deleted !== 'Y' && !inactive)
        return (
          <Link
            to={{
              pathname: Routes.WIP_CREATE.toLink(),
              state: {
                backLink: { to: Routes.FEATURE_SEARCH.toLink(), text: 'Feature Search' },
                name,
                featureId,
                featureProductId: productId,
              },
            }}
          >
            Create WIP
          </Link>
        );

      return null;
    },
  },
];

export default featureSearchTableColumns;
